import { useMemo } from 'react'
import { BlockProps, IconCard } from '@/types'
import { ButtonVariantProps } from '../button/button.variants'
import { IconCardVariantProps, iconCard } from './icon-card.variants'

export type useIconCardProps = BlockProps<IconCard, IconCardVariantProps>

export const useIconCard = (props: useIconCardProps) => {
  const {
    className = '',
    theme = 'dark',
    sectionName,
    appearance = 'ghost',
    data
  } = props

  const { iconColor } = data
  const _iconColor = !iconColor
    ? appearance === 'inline'
      ? 'white'
      : 'acqua'
    : iconColor

  const styles = useMemo(
    () => iconCard({ theme, iconColor: _iconColor, appearance }),
    [theme, _iconColor, appearance]
  )

  const buttonColor: ButtonVariantProps['color'] =
    theme === 'dark' ? 'acqua' : 'acquaDark'

  const isAppearanceInline = appearance === 'inline'
  const isAppearanceInlineVideo = appearance === 'inline_video'

  return {
    theme,
    className,
    sectionName,
    buttonColor,
    data,
    styles,
    isAppearanceInline,
    isAppearanceInlineVideo
  }
}
