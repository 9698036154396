/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react'
import { AlternateURLContext } from '@/contexts/AlternateURLProvider'
import { type Alternate } from '@/types/strapi/util'

export type useAlternateUpdaterProps = {
  alternates?: Alternate[]
}

export const useAlternateUpdater = (props: useAlternateUpdaterProps) => {
  const { alternates = [] } = props
  const { setState } = useContext(AlternateURLContext)

  useEffect(() => {
    setState({ alternates })
  }, [alternates])
}
